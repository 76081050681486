'use client'

// import { getAutocompleteSuggestions } from 'api/search'
import useAutoComplete from 'app/search/useAutocomplete'
import Image from '@/components/ui-other/basic/Image'
import { ReactNode, Ref, useState } from 'react'
import { Search, X } from 'react-feather'
import checkLogo from 'shared/imageHelpers'

import Link from '@/components/router/Link'
import { MouseEvent } from 'react'
import { onClickOutside } from 'shared/domHelpers'
import { searchPath } from 'shared/urlHelpers'
// import { getAutocompleteSuggestions } from 'api/search'

const NewHeaderSearchField = (): ReactNode => {
  const [isOpen, setIsOpen] = useState(true)
  const refClickableOutside: Ref<HTMLFormElement> = onClickOutside(() =>
    setIsOpen(false)
  )

  const {
    bindInput,
    bindOptions,
    bindOption,
    isBusy,
    suggestions,
    selectedIndex,
    setTextValue
  } = useAutoComplete({
    setIsOpen: setIsOpen,
    delay: 100,
    source: async (query) => {
      try {
        // const results = await getAutocompleteSuggestions({
        //   query
        // })

        return []
        // return results.data.map((result) => ({
        //   value: result.id,
        //   label: result.name,
        //   type: result.targetType ? result.targetType : undefined,
        //   url: result.targetType ? result.targetId : undefined,
        //   logo: result.targetLogo ? result.targetLogo : undefined
        // }))
      } catch (e) {
        return []
      }
    }
  })
  //delete input value
  function clearInput(event: MouseEvent<HTMLButtonElement>): void {
    setTextValue('')
  }

  // TODO : when selecting an item with a url, it should directly open the target urls
  //        urls per type still need to be created
  // TODO : when selecting an item without an url the value should just be set in the search field,
  //        allowing to continue narrowing down the result.
  //        Maybe we should also refresh the search then (if it's fast enough), but allow the user to continue typing

  // const handleSubmit = (event: any) => {
  //   event.preventDefault()
  //   // Navigate to the search page with the query
  //   console.log('value', value)
  //   if (value) {
  //     router.push(`/search/${encodeURIComponent(value.value)}`)
  //   }
  // }

  const noResults =
    !isBusy && suggestions.length == 0 && bindInput.value.length >= 1

  return (
    <div className="grow max-w-[18rem] sm:max-w-[30rem]">
      <form className="form relative" ref={refClickableOutside}>
        <div className="flex items-center w-full">
          <input
            type="text"
            placeholder=" "
            className={` w-full peer pl-10 px-1 outline-none  !bg-gray-50 focus:!bg-white hover:!border-gray-900
            `}
            {...bindInput}
            onClick={() => setIsOpen(true)}
          />

          {/* {isBusy && (
              <div className="w-4 h-4 border-2 border-dashed rounded-full border-slate-500 animate-spin"></div>
            )} */}
          <div
            className="absolute  top-1/2 -translate-y-1/2 whitespace-nowrap transition-all flex items-center gap-1 pointer-events-none  pl-2
             peer-placeholder-shown:text-gray-600 peer-placeholder-shown:left-1/2 peer-placeholder-shown:-translate-x-1/2
              peer-focus:text-transparent peer-focus:left-2 peer-focus:translate-x-0
              text-transparent left-2 translate-x-0"
          >
            <Search className="w-4 h-4 !text-gray-600 shrink-0 sm:mb-0.5" />{' '}
            <span className="sm:hidden">Suche</span>
            <span className="hidden sm:block ">
              Finde Vereine, Teams, Ligen und mehr…
            </span>
          </div>
          {bindInput.value && (
            <button
              type="button"
              className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-600 hover:text-gray-800"
              onClick={clearInput}
            >
              <X className="w-4 h-4 " />
            </button>
          )}
        </div>
        {/* TODO: Füge einen Reset-Button hinzu */}
        {/* Ergebnisse abfragen, Positionierung anpassen, Anzahl begrenzen */}
        {bindInput.value && isOpen && (
          <div
            className="scroll-smooth flex flex-col justify-center left-0 right-0 top-full absolute overflow-hidden bg-white rounded-b shadow-md "
            style={{
              // height: "33.5rem",
              maxHeight: 'calc(100dvh - 8rem)'
            }}
          >
            {!noResults && (
              <ul
                {...bindOptions}
                className="pt-2 pb-2 overflow-x-hidden overflow-y-auto flex-grow"
              >
                {suggestions.slice(0, 10).map((option, index) => {
                  let displayType

                  switch (option.type) {
                    case 'League':
                      displayType = 'Liga'
                      break
                    case 'Tournament':
                      displayType = 'Turnier'
                      break
                    case 'Club':
                      displayType = 'Verein'
                      break
                    case 'Team':
                      displayType = 'Mannschaft'
                      break
                    case 'Field':
                      displayType = 'Halle'
                      break
                    default:
                      displayType = option.type
                  }
                  return (
                    <li className={``} key={index} {...bindOption}>
                      <button
                        className={`flex items-start text-left  px-2 py-1.5 hover:bg-gray-100 w-full ${
                          selectedIndex === index ? 'bg-gray-100' : ''
                        }`}
                      >
                        <div className="flex justify-center w-8  shrink-0  ">
                          <SearchResultType
                            logo={option.logo}
                            type={option.type}
                          />
                        </div>
                        <div className="pt-0.5">
                          {option.type ? (
                            <div className="leading-5">
                              <div className="">{option.label} </div>
                              <div className="text-gray-400 text-sm">
                                {displayType}
                              </div>
                            </div>
                          ) : (
                            <div className="leading-4">{option.label} </div>
                          )}
                        </div>
                      </button>
                    </li>
                  )
                })}
              </ul>
            )}
            {/* {noResults && (
              <div className="flex items-center justify-center w-full  pt-6 pb-4 italic text-center bold text-gray-600 ">
                <div>Keine Suchvorschläge</div>
              </div>
            )} */}

            <div className="flex justify-center pt-2 mb-3">
              <Link
                className="btn btn-gray"
                href={searchPath({ q: bindInput.value })}
              >
                Suchen
              </Link>
            </div>
          </div>
        )}
      </form>
    </div>
  )
}

export default NewHeaderSearchField

function SearchResultType({
  logo,
  type
}: {
  logo: string | undefined
  type: string | undefined
}): ReactNode {
  switch (type) {
    case 'League':
    case 'Tournament':
      return (
        <Image
          src={checkLogo(logo, 'tournament')}
          className="w-6 h-6"
          objectFit="contain"
          objectPosition="top"
        />
      )
    case 'Club':
      return (
        <Image
          src={checkLogo(logo, 'club')}
          className="w-6 h-6 "
          objectFit="contain"
        />
      )
    case 'Field':
      return (
        <Image
          src={checkLogo(logo, 'field')}
          className="w-6 h-6 pt-0.5"
          objectFit="contain"
          objectPosition="top"
        />
      )
    default:
      return <Search className="w-4 h-4 object-contain object-top" />
  }
}
