'use client'

import { useUserProfile } from 'auth/useUserProfile'
import HBButton from '@/components/ui-other/basic/HBButton'

import { useSession } from 'lib/session-client'
import Link from 'next/link'

import { ReactElement, Suspense } from 'react'
import { User } from 'react-feather'
import { myProfilePath } from 'shared/urlHelpers'

const NewHeaderUserInfo = (): ReactElement => {
  const [session] = useSession()
  const { userProfile } = useUserProfile(false)

  return (
    <Suspense fallback={<></>}>
      {!session && (
        <HBButton className="shrink-0 w-52 h-full">
          <User className="mr-3" />
          Anmelden
        </HBButton>
      )}

      {session && (
        <div className="shrink-0 flex flex-col items-end">
          <Link
            href={myProfilePath()}
            className="text-primary flex items-center whitespace-nowrap gap-1.5"
          >
            <User className="w-4 h-4 shrink-0 -mt-1" />
            <span className="min-w-0 overflow-hidden text-ellipsis font-bold">
              {userProfile?.firstname}
            </span>
          </Link>
          <div className="text-sm text-gray-500">
            <Link
              className="hover:text-primary active:text-primary-dark"
              href={`${myProfilePath()}`}
            >
              Meine Teams & Ligen
            </Link>
            {/* &middot;{' '}
          <Link
            className="hover:text-primary active:text-primary-dark"
            href={`${myProfilePath()}#ligen-a`}
          >
            Meine Ligen
          </Link> */}
          </div>
        </div>
      )}
    </Suspense>
  )
}

export default NewHeaderUserInfo
