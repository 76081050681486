'use client'
import { useSession } from 'lib/session-client'
import Link from '@/components/router/Link'
import { loginPath, myProfilePath, signupPath } from 'shared/urlHelpers'
import { ComponentType } from 'react'

type SpecialLinkType = 'login/profile' | 'register'

const SpecialLink: ComponentType<{ type: SpecialLinkType }> = ({ type }) => {
  const [session] = useSession()
  switch (type) {
    case 'login/profile':
      return session ? null : <Link href={signupPath()}>Registrieren</Link>
    case 'register':
      return session ? (
        <Link href={myProfilePath()}>Mein Profil</Link>
      ) : (
        <Link href={loginPath()}>Einloggen</Link>
      )
  }
}

export default SpecialLink
